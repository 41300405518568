// /** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import React from 'react'
import { Fragment } from 'react'
import Layout from '../../@lekoarts/gatsby-theme-cara/components/layout'
import Head from '../../@lekoarts/gatsby-theme-cara/components/Home/Head'
import { FooterList } from '../../@lekoarts/gatsby-theme-cara/components/FooterList'
import Footer from '../../@lekoarts/gatsby-theme-cara/components/footer'
import Main from '../../@lekoarts/gatsby-theme-cara/components/BlogDetail/Main'

const BlogDetail = ({ params }) => {
  const { id } = params

  return (
    <Layout>
      <Head />
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100vh',
          overflow: 'hidden scroll',
          transform: 'translate3d(0px, 0px, 0px)',
          fontFamily: 'Noto Sans JP, sans-serif',
        }}
      >
        <Box
          sx={{
            overflow: 'hidden',
            position: 'absolute',
            width: '100%',
            transform: 'translate3d(0px, 0px, 0px)',
          }}
        >
          <Fragment>
            <Main id={id} />
            <FooterList />
            <Footer />
          </Fragment>
        </Box>
      </Box>
    </Layout>
  )
}

export default BlogDetail
