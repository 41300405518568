/** @jsx jsx */
import React from 'react'
import { Box, jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { articles } from '../../../../utils/news'

export const Main = ({ id }: { id: string }) => {
  const popularArticles = articles.slice(0, 3)
  const relatedArticles = articles.slice(3, 6)

  return (
    <Box
      sx={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: ['16px', '24px'],
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
      }}
    >
      {/* Main Content */}
      <Box sx={{ flex: '1 1 auto', paddingRight: [0, '32px'] }}>
        {/* Breadcrumbs */}
        <Box sx={{ marginBottom: '16px' }}>
          <nav aria-label="breadcrumb">
            <ol
              sx={{
                listStyle: 'none',
                display: 'flex',
                flexWrap: 'wrap',
                padding: 0,
                margin: 0,
                fontSize: '14px',
              }}
            >
              <li>
                <Link to="/">ホーム</Link>
              </li>
              <li sx={{ margin: '0 8px' }}>/</li>
              {/* 下記、無効化中 */}
              <li>
                <Link to="">記事一覧</Link>
              </li>
              <li sx={{ margin: '0 8px' }}>/</li>
              <li aria-current="page">{articles[parseInt(id) - 1]?.title}</li>
            </ol>
          </nav>
        </Box>

        {/* {articles.map((articles) => (
          <Box sx={{ marginBottom: '24px' }}>
            <h1 sx={{ fontSize: '32px', margin: 0 }}>{articles.description}</h1>
          </Box>
        ))} */}

        {/* Title */}
        <Box sx={{ marginBottom: '24px' }}>
          <h1 sx={{ fontSize: '32px', margin: 0 }}>
            {articles[parseInt(id) - 1]?.title}
          </h1>
        </Box>

        {/* Social Icons */}
        <Box sx={{ marginBottom: '24px', display: 'flex', gap: '16px' }}>
          <a href="https://twitter.com/share">
            <img
              src="/twitter.png"
              alt="Twitter"
              sx={{ width: '32px', height: '32px' }}
            />
          </a>
          <a href="https://www.facebook.com/sharer/sharer.php">
            <img
              src="/facebook.png"
              alt="Facebook"
              sx={{ width: '32px', height: '32px' }}
            />
          </a>
          <a href="https://social-website.com/share">
            <img
              src="/instagram.png"
              alt="instagram"
              sx={{ width: '32px', height: '32px' }}
            />
          </a>
        </Box>

        {/* Content */}
        <Box sx={{ fontSize: '18px', lineHeight: '1.8', color: '#333' }}>
          {articles[parseInt(id) - 1]?.description}
        </Box>
      </Box>

      {/* Sidebar */}
      <Box
        sx={{
          width: ['100%', '300px'],
          flex: '0 0 auto',
          marginTop: ['40px', '0'],
        }}
      >
        {/* Popular Articles */}
        <Box sx={{ marginBottom: '40px' }}>
          <h2 sx={{ fontSize: '24px', marginBottom: '16px' }}>人気記事</h2>
          {popularArticles.map((article, index) => (
            <Link to={article.href}>
              <Box key={index} sx={{ marginBottom: '24px' }}>
                <Box>
                  <img
                    src={article.image}
                    alt={article.title}
                    sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Box>
                <Box
                  sx={{
                    fontSize: '16px',
                    marginTop: '8px',
                    color: '#0073e6',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  {article.title}
                </Box>
              </Box>
            </Link>
          ))}
        </Box>

        {/* Related Articles */}
        <Box>
          <h2 sx={{ fontSize: '24px', marginBottom: '16px' }}>関連記事</h2>
          {relatedArticles.map((article, index) => (
            <Link to={article.href}>
              <Box key={index} sx={{ marginBottom: '24px' }}>
                <Box>
                  <img
                    src={article.image}
                    alt={article.title}
                    sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                  />
                </Box>
                <Box
                  sx={{
                    fontSize: '16px',
                    marginTop: '8px',
                    color: '#0073e6',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                >
                  {article.title}
                </Box>
              </Box>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default Main
